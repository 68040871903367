import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { StaticContext } from 'react-router';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import { Location, LocationDescriptor, LocationState } from 'history';
import { arrays } from '@swuif/utils';
import { layoutActions, NavigationItem, useLayout } from 'swuif';
import { AppItem } from './types';
import { Select } from 'antd';
import { FieldInput, FieldSelect } from 'swuif';
import ViewSvg from '@/assets/recommendation/icon-view.svg';
// tslint:disable-next-line:no-var-requires
const RouterContext = require('react-router').__RouterContext;

// FIXME:  use official API when https://github.com/ReactTraining/react-router/pull/6453 merged

export function useRouter<
  P extends { [K in keyof P]?: string } = {},
  C extends StaticContext = StaticContext,
  S = LocationState
>() {
  return useContext(RouterContext) as RouteComponentProps<P, C, S>;
}

const matchLocation = (
  location: Location,
  path?: LocationDescriptor,
  exact: boolean = false,
  strict: boolean = false,
): boolean => {
  if (path) {
    return (
      matchPath(location.pathname, {
        path: typeof path === 'string' ? path : path.pathname,
        exact,
        strict,
      }) !== null
    );
  }
  return false;
};

const keys = (
  location: Location,
  opened: string[],
  items: NavigationItem[],
  depth: number,
  appFound: string[] = [],
): boolean => {
  if (depth > 3) {
    return false;
  }
  return items
    .map(item => {
      let childrenMatchLoc = false;
      let match = matchLocation(location, item.path, false, item.strict);
      if (match) appFound.push(item.key);
      if (
        item.children &&
        item.children.length > 0 &&
        keys(location, opened, item.children, depth + 1, appFound)
      ) {
        opened.push(item.key);
        childrenMatchLoc = true;
      }
      return childrenMatchLoc || match;
    })
    .some(t => t);
};

const childrenIter = (item: NavigationItem) => item.children || [];

export const openedKeys = (location: Location, items: AppItem[]) => {
  const opened: string[] = [];
  let active: string = '';
  const appFound: string[] = [];
  let keyObject = Object.keys(items);
  for (var i = 0; i < keyObject.length; i++) {
    keys(location, opened, items[keyObject[i]].items, 0, appFound);
    if (appFound.length > 0 && active === '') active = items[keyObject[i]].key;
  }
  return { opened: opened, active: active };
};

export const selectedKeys = (
  location: Location,
  items: AppItem[],
): NavigationItem[] => {
  let navItem: NavigationItem[] = [];
  let key = Object.keys(items);
  for (var i = 0; i < key.length; i++) {
    navItem = [
      ...navItem,
      ...arrays.flattenMapDeep(items[key[i]].items, childrenIter),
    ];
  }
  return navItem.filter(item =>
    matchLocation(location, item.path, item.exact, item.strict),
  );
};

export const getNavItem = (items: AppItem[], key: string) => {
  const appIt = items.filter((appItem: AppItem) => appItem.key === key);
  return appIt.length > 0 ? appIt[0].items : [];
};

export const useManagedNav = (items: AppItem[]) => {
  const { location } = useRouter();
  const [{ sideNavCollapsed }, dispatch] = useLayout();
  const [selected, setSelected] = useState<string[]>([]);
  const match = openedKeys(location, items);
  const [opened, setOpened] = useState<string[]>(() => match.opened);
  const [activeApp, setActiveApp] = useState<string>(match.active);
  const [selectedDetail, setSelectedDetail] = useState<NavigationItem>({
    key: '',
  });
  const [navItem, setNavItem] = useState<NavigationItem[]>(() =>
    getNavItem(items, match.active),
  );

  const onOpenChange = (keys: string[]) => {
    const keysDiff = keys.filter((key: any) => opened.indexOf(key) === -1);
    const latestOpenKey = keysDiff[keysDiff.length - 1];

    let openKeys: string[] = [];
    if (keysDiff.length === 0) {
      keys.forEach((key: any) => openKeys.push(key));
    }
    if (latestOpenKey) {
      // 2 Steps menu handler
      if (
        latestOpenKey === 'local_resource' ||
        latestOpenKey === 'global_resource' ||
        latestOpenKey === 'universal_resource'
      ) {
        openKeys.push('sd');
        openKeys.push('mall');
      } else if (latestOpenKey === 'content') {
        openKeys.push('dashboard');
      } else if (
        latestOpenKey === 'assets-guide' ||
        latestOpenKey === 'recom-guide' ||
        latestOpenKey === 'gse-guide' ||
        latestOpenKey === 'explore-guide' ||
        latestOpenKey === 'sd-guide' ||
        latestOpenKey === 'plugin-guide' ||
        latestOpenKey === 'vda-guide' ||
        latestOpenKey === 'routine-guide' ||
        latestOpenKey === 'find-guide'
      ) {
        openKeys.push('guide');
      } else if (['mobile', 'tv', 'retail'].indexOf(latestOpenKey) > -1) {
        openKeys.push('explore');
      }

      openKeys.push(latestOpenKey);
    }

    const currentLocation = openedKeys(location, items).opened;
    let currentStillOpen: boolean = true;
    currentLocation.forEach((loc: string) => {
      if (keys.indexOf(loc) < 0) currentStillOpen = false;
    });

    if (currentStillOpen) {
      if (!sideNavCollapsed) {
        openKeys = [...openKeys, ...openedKeys(location, items).opened];
      }
      setOpened(openKeys);
    } else {
      if (openKeys.length === 0) setOpened(keys);
      else setOpened(openKeys);
    }
  };

  const onChangeActiveApp = (key: string) => {
    setActiveApp(key);
    setNavItem(getNavItem(items, key));
    dispatch(layoutActions.sideNavCollapse(false));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const selectedItem = selectedKeys(location, items);
      setSelected(selectedItem.map(item => item.key));
      setSelectedDetail(selectedItem[0]);
    }

    return () => {
      mounted = false;
    };
  }, [location, items]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (sideNavCollapsed) {
        setOpened([]);
      } else {
        setOpened(openedKeys(location, items).opened);
      }
    }

    return () => {
      mounted = false;
    };
  }, [items, location, sideNavCollapsed]);

  return {
    selected,
    opened,
    onOpenChange,
    activeApp,
    onChangeActiveApp,
    navItem,
    selectedDetail,
  };
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export const generateOption = (
  list: any[],
  valueField: string,
  labelField: string,
) => {
  return list.map((obj: any) => (
    <Select.Option key={obj[valueField]} value={obj[valueField]}>
      {obj[labelField]}
    </Select.Option>
  ));
};

export const getImageBySource = (sourceFile: any) => {
  return <img alt="" width={32} src={sourceFile} />;
};

export const getImage24BySource = (sourceFile: any) => {
  return <img alt="" width={24} src={sourceFile} />;
};

export const getViewIconImage = () => {
  return (
    <img
      alt=""
      width={32}
      color="#0197fe"
      style={{ fill: 'red' }}
      src={ViewSvg}
    />
  );
};

export const htmlEntityDecode = (str: any) => {
  const entityPattern = /&#x?([A-F0-9]+);/gi; // RegExp('&#x?([A-F0-9]+);', 'gi')
  return !str
    ? ''
    : str.replace(entityPattern, (match: any, numStr: any) => {
        const base = /&#x.*/.test(match) ? 16 : 10;
        const num = parseInt(numStr, base);
        console.log('htmlEntityDecode : ', String.fromCharCode(num));
        return String.fromCharCode(num);
      });
};

export const generateAdvancedSearchFieldComponentByType = (
  id: string,
  label: string,
  type: string,
  selectOptions?: any,
) => {
  switch (type) {
    case 'input':
      return (
        <FieldInput
          id={id}
          label={label}
          grid={{ sm: 24, md: 24, lg: 24 }}
          labelCol={{ sm: 24, md: 24, lg: 24 }}
          wrapperCol={{ sm: 24, md: 24, lg: 24 }}
          role="textbox"
        />
      );
    case 'select':
      return (
        <FieldSelect
          id="countryCode"
          label="Countries"
          grid={{ sm: 24, md: 24, lg: 24 }}
          labelCol={{ sm: 24, md: 24, lg: 24 }}
          wrapperCol={{ sm: 24, md: 24, lg: 24 }}
          showSearch
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {generateOption(selectOptions, 'value', 'label')}
        </FieldSelect>
      );
    case 'select-simple':
      return (
        <FieldSelect
          id={id}
          label={label}
          grid={{ sm: 24, md: 24, lg: 24 }}
          labelCol={{ sm: 24, md: 24, lg: 24 }}
          wrapperCol={{ sm: 24, md: 24, lg: 24 }}
        >
          {generateOption(selectOptions, 'value', 'label')}
        </FieldSelect>
      );
    default:
      return null;
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const parseAndCapitalizeFirstLetter = (str: string) => {
  const splitted = str.split('-');
  const capitalizedString: string[] = [];
  splitted.forEach((s: string) =>
    capitalizedString.push(capitalizeFirstLetter(s)),
  );
  const res = capitalizedString.join(' ');
  return res;
};

export const compareStatus = (strA: any, strB: any) => {
  if (strA === strB) return 0;
  if (!strA) return 1;
  if (!strB) return -1;
  if (strA < strB) return -1;
  return 1;
};

export const convertPxToNum = (str: any) => {
  if (!str) return 0;
  const strNum: string = str.slice(0, -2);
  const num: number = Number(strNum);
  return num;
};
