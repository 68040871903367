import {
  domainToKey,
  keyToDomain,
  ROLE_LEVEL,
  ROLE_TYPE,
  SERVICE_ROLES,
} from './constants';

export const viewerRole = (role: string) => {
  if (role === ROLE_TYPE.GLOBAL_VIEWER || role === ROLE_TYPE.COUNTRY_VIEWER)
    return true;
  return false;
};

export const adminRole = (role: string) => {
  if (
    role === ROLE_TYPE.COUNTRY_GENERAL_ADMIN ||
    role === ROLE_TYPE.COUNTRY_SERVICE_ADMIN ||
    role === ROLE_TYPE.GLOBAL_GENERAL_ADMIN ||
    role === ROLE_TYPE.GLOBAL_SERVICE_ADMIN
  )
    return true;
  return false;
};

export const managerRole = (role: string) => {
  if (
    role === ROLE_TYPE.OPERATION ||
    role === ROLE_TYPE.GLOBAL_MANAGER ||
    role === ROLE_TYPE.COUNTRY_MANAGER
  )
    return true;
  return false;
};

export const globalRole = (role: string) => {
  if (
    role === ROLE_TYPE.OPERATION ||
    role === ROLE_TYPE.GLOBAL_MANAGER ||
    role === ROLE_TYPE.GLOBAL_GENERAL_ADMIN ||
    role === ROLE_TYPE.GLOBAL_SERVICE_ADMIN
  )
    return true;
  return false;
};

export const checkUserRole = (userRoles: any, checkRoles: string[]) => {
  let found = false;
  Object.keys(userRoles).forEach(service => {
    if (checkRoles.indexOf(userRoles[service]) > -1) {
      found = true;
      return;
    }
  });
  return found;
};

export const checkHighestRole = (userRoles: any) => {
  let highestRoleLevel = 0;
  let highestRoleMenu = '';
  Object.keys(userRoles).forEach(cl => {
    if (highestRoleLevel === 0) {
      highestRoleLevel = ROLE_LEVEL[userRoles[cl]];
      highestRoleMenu = cl;
    } else {
      if (ROLE_LEVEL[userRoles[cl]] < highestRoleLevel) {
        highestRoleLevel = ROLE_LEVEL[userRoles[cl]];
        highestRoleMenu = cl;
      }
    }
  });
  return {
    highestRoleValue: userRoles[highestRoleMenu],
    highestRoleMenu: highestRoleMenu,
  };
};

export const extractEmail = (email: string) => {
  const emailArr = email.split('@');
  const userId = emailArr[0];
  const domain = emailArr[1] || '';
  let key = domainToKey[domain] || '**';
  return userId + (key && `#${key}`);
};

export const constructEmail = (id: string) => {
  const strArr = id.split('#');
  const userId = strArr[0];
  const key = strArr[1] || '';
  let domain = keyToDomain[key];
  return userId + (domain && `@${domain}`);
};

export const extractId = (email: string) => {
  const emailArr = email.split('@');
  const userId = emailArr[0];
  return userId;
};

export const getTableContentWidth = (
  columns: any[],
  rowSelection: boolean = false,
  expandable: boolean = false,
) => {
  let x: number = 0;
  columns.forEach((col: any) => {
    if (!col['hidden'] && col['width']) {
      const width: number = Number(
        col['width'].toString().match(/-?\d+\.?\d*/),
      );
      x += width;
    }
  });
  x += 96;
  if (expandable) x += 40;
  if (rowSelection) x += 40;
  return x;
};

export const isRoleExist = (role: any) => {
  let exist = false;
  Object.keys(SERVICE_ROLES).forEach(s => {
    if (!!role[s]) {
      exist = true;
      return;
    }
  });
  return exist;
};

export const checkIsReadOnly = (
  credential: any,
  countries: any,
  service: string,
) => {
  if (credential) {
    if (credential.user.roles[service].indexOf('GLOBAL') > -1) {
      return false;
    } else if (credential.user.roles[service].indexOf('COUNTRY') > -1) {
      if (countries.length >= 249) return true;
      let userCountries = new Set(credential.user.countries[service]);
      for (let contentCountries of countries) {
        if (!userCountries.has(contentCountries)) {
          return true;
        }
      }
    }
  }
  return false;
};

export const headerHeight = () => {
  let elem = document.getElementsByClassName('ant-table-body')[0];
  if (elem && elem.getBoundingClientRect()) {
    return elem.getBoundingClientRect().top;
  }
  return 300;
};
